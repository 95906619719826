import * as React from 'react';
import {
  IconButton,
  Grid,
  Fade,
  Popper,
  Checkbox,
  Tooltip,
  Box, Menu, MenuItem, ClickAwayListener, Paper
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useLayers } from 'components/providers/LayersProvider';
import { useTranslation } from 'components/providers/TranslationProvider';
import CloseIcon from '@mui/icons-material/Close';
import GetAppRoundedIcon from '@mui/icons-material/GetAppRounded';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  MuiBoxColor,
  MuiBoxContainer, MuiBoxIconContainerSecondLevel,
  MuiGridColorAndText,
  MuiGridContainerLegend,
  MuiGridControlButtons,
  MuiGridItemContainer,
  MuiGridItemLegend,
  MuiGridRoot,
  MuiIconButton,
  MuiLayerName, MuiLegendName,
  MuiTitle,
  MuiVisibilityIcon,
  MuiVisibilityOffIcon
} from './styles/legend';
import { ReactSVG } from 'react-svg';
import useExportLayer from 'api/hooks/useExportLayer';
import { usePermissions } from 'components/hooks/usePermissions';
import {setPolygonDataLoading} from 'store/appSlice';
import { useState , useRef} from 'react';
import { setLogicalOperator } from 'lib/react-geo-tool/packages/react-redux/src/slices/cartoSlice';
import {LayersOutlined} from "@mui/icons-material";
import {buttonClose} from "./BaseMapSelector";
import AndOrSwitch from "../../../../../common/AndOrSwitch";
import { buildQuery } from "../../../../../../utils/supportComponents";
import { useTheme } from '../../../../../providers/CustomThemeProvider';
import LayerDescription from 'components/common/LayerDescription';


const Legend = (props) => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const layers = useSelector((state) => state.app.layers);
  const sources = useSelector((state) => state.carto.dataSources);
  const elRef = React.useRef(null);
  const [open, setOpen] = React.useState(true);
  const { mutateAsync : downloadLayers} = useExportLayer();
  const { changeVisible } = useLayers();
  const dispatch = useDispatch();
  const allowUpgradeOperator = useSelector((state) => state.app.allowUpgradeOperator);
  const showDownloadLayer = useSelector((state) => state.app.showDownloadLayer);

  const setActive = ({ checked, id }, isRecommendationA = false , isOfferA = false ) => changeVisible(id, checked, isRecommendationA, isOfferA);
  const activeWidget = useSelector((state) => state.app.selectWidgetId);
  const spatialFilters = useSelector((state) => state.app.listFilterPolygonData);
  const [spatialFilter , setSpatialFilter] = useState();
  const mapId = (location.pathname.split('/'))[6];
  const widgets = useSelector((state) => state.app.widgets);
  const { hasPermissionFor } = usePermissions();
  const parametersCatalog = useSelector((state)=>state.app.parametersCatalog);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedLayer, setSelectedLayer] = useState(null);
  const popperRef = useRef(null);

  const handleShow = (event, layer) => {
    setSelectedLayer(layer);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
    if (popperRef.current && popperRef.current.contains(event.target)) {
      return;
    }
    setAnchorEl(null);
    setSelectedLayer(null);
  };

  const {
    mapComponent,
    setMapComponent
  } = props;

  const showOrHideRelatedRecommendationsLayers = ( { checked, id } ) => {
    setActive({checked, id});
    if (id.includes('_fixed')) {
      const keySubstring = id.split('_')[0];
      const params = widgets.find( (w) => {
        return w.id ===  keySubstring
      }).params.layersConfiguration;

      setActive({checked, id}, params?.recommendedAreaLayerVisibility ,params?.currentOfferAreaLayerVisibility);
    }
  }

  const handleClick = () => {
    setOpen((prevSt) => !prevSt);
    setMapComponent('legend');
  };
 const handleOperator = ({id}) => {
  let source;
  let op;
  for (let index = 0; index < layers.length; index++) {
    if (id == layers[index].id) {
      source = layers[index].id;
      if (sources[source]){
        op = sources[source].filtersLogicalOperator;
        if(op === 'and') {
          dispatch(setLogicalOperator({sourceId: source, operator:'or'}));
        } else{
          dispatch(setLogicalOperator({sourceId: source, operator:'and'}));
        }
      }
    }
  }
 };

 React.useEffect(() => {
  if (activeWidget && spatialFilters) {
    const key = `${activeWidget}-${mapId}`;
    setSpatialFilter(spatialFilters[key]);
  }else{
    setSpatialFilter();
  }
}, [spatialFilters]);

  React.useEffect(() => {
    if (mapComponent !== 'legend') {
      setOpen(false);
    }
  }, [mapComponent]);

  const downloadLayer = ({id}) => {
    let source;
    let filters = '';
    let data;
    const tableName = layers.find((l) => l.id.includes(activeWidget))?.isRecommendation ? layers.find((l) => l.id.includes(activeWidget+'_fixed_false'))?.datasetName :
      layers.find((l) => l.id.includes(activeWidget))?.isPolygon ? layers.find((l) => l.id.includes(activeWidget))?.datasetName : ''
    for (let index = 0; index < layers.length; index++) {
      if (id == layers[index].id) {
        if (id.includes('_fixed')){
          data =  sources[id].data.replace("geom", "geom , ido, w_var_covered, quant_var_covered");
        }else {
          data = sources[id].data;
        }
        source ={'id': sources[id].id , 'filters': sources[id].filters,'data': data,
          'datasetName': sources[id].datasetName , 'filtersLogicalOperator' :sources[id].filtersLogicalOperator,
          'tableFilter': tableName};
        if (source.filters && Object.keys(source.filters).length >0){
          filters = buildQuery(source.filters, widgets, source?.filtersLogicalOperator);
        }
      }
    }
    dispatch(setPolygonDataLoading(true));
    downloadLayers({
      spatialFilter, source, filters, parameters: parametersCatalog
    }).then(async (data) => {
        const geoJsonString = JSON.stringify(data['value']);
        const blob = new Blob([geoJsonString], { type: 'application/geo+json' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `${source.datasetName}.geojson`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        dispatch(setPolygonDataLoading(false));
        setAnchorEl(null);
        setSelectedLayer(null);
   });
};

  const id = open ? 'spring-popper' : undefined;

  const getDinamicHeight = () =>{
    let height = 46;
    layers?.map( (layer) =>{
      if (layer.legend.length > 0 && layer.visible){
        height+= 27 + 27 * layer.legend.length
      } else if (layer.isRecommendation){
        height+= 27
      } else if (layer.isPolygon){
        height+= 27
      }else{
        height+= 27
      }
    });
    return height
  }

  const getPositionDinamic = () => {
    return allowUpgradeOperator && showDownloadLayer? 8: allowUpgradeOperator? 8: showDownloadLayer ? 9: 10
  }

  return (
    <>
      <MuiIconButton
        ref={elRef}
        variant='contained'
        color={open ? 'secondary' : 'primary'}
        onClick={handleClick}
        aria-describedby={id}
      >
        <LayersOutlined style={{
          width: 24,
          height: 24,
          color: theme.palette.primary.main,
        }}/>
      </MuiIconButton>
      { elRef.current && (<Popper id={id} open={open} anchorEl={elRef.current} placement='bottom-start' transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <MuiGridRoot container direction='column'  style={{width: allowUpgradeOperator && showDownloadLayer
                ? 302
                : allowUpgradeOperator
                  ? 245
                  : showDownloadLayer
                    ? 265
                    : 255
            }}>
              <IconButton style={buttonClose} onClick={handleClick}>
                <CloseIcon/>
              </IconButton>
              <MuiTitle>{t('layers')}</MuiTitle>
              <MuiGridContainerLegend container style={{height: getDinamicHeight()}}>
                {layers?.map((layer) => (
                  <React.Fragment key={ `container-legent-${layer.id}` }>
                    <MuiGridItemContainer container key={`legend-layer-container-${layer.id}`}>
                        <MuiGridColorAndText item xs={getPositionDinamic()}>
                          {layer.geometry !== 'Point' && (
                            <Grid item xs={2}>
                              <MuiBoxColor style={{ background: layer.color, border: `2px solid ${layer.borderColor}` }} />
                            </Grid>
                          )}
                          { layer.geometry === 'Point' && layer.legendType === 'simple' && (
                            <Grid item xs={2}>
                              <MuiBoxContainer style={{ background: layer.color }} >
                                {layer.customIcon !== 'circle.svg' ? (
                                <ReactSVG
                                  src={process.env.PUBLIC_URL + '/maki-icons/' + (layer.customIcon? layer.customIcon : "marker.svg")}
                                  afterInjection={(svg) => {
                                    svg.getElementsByTagName('path')[0]
                                      .setAttribute('fill', '#ffffff');
                                    svg.setAttribute('height', '16px');
                                    svg.setAttribute('width', '16px');
                                    svg.setAttribute('style', 'display: flex');
                                  }}
                                  beforeInjection={(svg) => {
                                    svg.classList.add('svg-class-name')
                                  }}/>) : (
                                  <ReactSVG
                                    src={process.env.PUBLIC_URL + '/maki-icons/' + (layer.customIcon? layer.customIcon : "marker.svg")}
                                    afterInjection={(svg) => {
                                      svg.getElementsByTagName('path')[0]
                                        .setAttribute('fill', layer.color);
                                    }}
                                    beforeInjection={(svg) => {
                                      svg.classList.add('svg-class-name')
                                    }}/>
                                )}
                              </MuiBoxContainer>
                            </Grid>
                          )}
                          { layer.geometry === 'Point' && layer.legendType === 'by_value' && (
                            <Grid item xs={1.5} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} >
                              <ReactSVG
                                src={process.env.PUBLIC_URL + '/maki-icons/' + ("circle.svg")}
                                afterInjection={(svg) => {
                                  svg.getElementsByTagName('path')[0]
                                    .setAttribute('fill', '#616161');
                                }}
                                beforeInjection={(svg) => {
                                  svg.classList.add('svg-class-name')
                                  svg.setAttribute('height', '9');
                                  svg.setAttribute('width', '9');
                                }}/>
                            </Grid>
                          )}
                          <Grid item xs={10}>
                          <Tooltip title={layer.name} key={`layer-tt-${layer.id}`} placement="top-start">
                              <MuiLayerName noWrap>
                                {layer.name }
                              </MuiLayerName>
                            </Tooltip>
                          </Grid>
                        </MuiGridColorAndText>
                      <MuiGridControlButtons item xs={2}>
                        {allowUpgradeOperator && (
                          <AndOrSwitch
                            id={layer.id}
                            onClick={(e) => handleOperator(e.target)}
                            disabled= {!layer.visible}
                          />
                        )}
                          <Checkbox
                            style={{width: '24px', height: '24px' , border:'1px solid #E0E0E0'}}
                            checked={layer.visible}
                            id={layer.id}
                            onClick={(e) => showOrHideRelatedRecommendationsLayers( e.target )}
                            color='primary'
                            icon={<MuiVisibilityOffIcon />}
                            checkedIcon={<MuiVisibilityIcon/>}
                          />
                        <IconButton
                          sx={{
                            border:'1px solid #E0E0E0',
                            width: '24px',
                            height: '24px'
                        }}
                          disabled={!layer.visible}
                        >
                          <MoreVertIcon style={{ width: '16px', height: '16px' }} onClick={(event)=>handleShow(event, layer)}/>
                        </IconButton>
                        <Popper open={Boolean(anchorEl)} anchorEl={anchorEl} placement="bottom">
                          <ClickAwayListener onClickAway={handleClose}>
                            <Paper
                              ref={popperRef}
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                gap: '4px',
                                width: 32,
                                padding: '4px',
                                borderRadius: "16px",
                                boxShadow: 3,
                                backgroundColor: "white",
                              }}
                            >
                              <IconButton onClick={()=>setAnchorEl(null)} sx={{width: '14px', height: '14px', padding: '1px', backgroundColor: '#EEEEEE'}}>
                                <CloseIcon sx={{color: '#616161', fontSize: '12px !important'}} />
                              </IconButton>
                              {showDownloadLayer && hasPermissionFor('download_layer') && (
                                    <IconButton
                                      style={{ width: '24px', height: '24px', padding: '4px'}}
                                      onClick={(event) => downloadLayer({ id: selectedLayer.id })}
                                      disabled={!selectedLayer?.visible}
                                    >
                                      <GetAppRoundedIcon sx={{ color: !selectedLayer?.visible ? '#BDBDBD' : '#616161' }} />
                                    </IconButton>
                                  )
                                  }
                              <LayerDescription title={selectedLayer?.name} description={selectedLayer?.description}/>
                            </Paper>
                          </ClickAwayListener>
                        </Popper>
                      </MuiGridControlButtons>
                        {layer.legend.length > 0 && layer.visible && (
                          <Grid container>
                            {layer.legend?.map((legend, index) => (
                              <MuiGridItemLegend container key={`legend-layer-visible-container-${layer?.legend?.id || index}`}>
                                <Grid item xs={2} >
                                  { layer.geometry === 'Point' && (
                                    <MuiBoxIconContainerSecondLevel
                                      style={{ background: legend.color, padding: 2 }} >
                                      <ReactSVG
                                        src={process.env.PUBLIC_URL + '/maki-icons/' + legend.icon}
                                        afterInjection={(svg) => {
                                          svg.getElementsByTagName('path')[0]
                                            .setAttribute('fill', '#ffffff');
                                          svg.setAttribute('height', '16px');
                                          svg.setAttribute('width', '16px');
                                          svg.setAttribute('style', 'display: flex');
                                        }}
                                        beforeInjection={(svg) => {
                                          svg.classList.add('svg-class-name')
                                        }}/>
                                    </MuiBoxIconContainerSecondLevel>
                                  )}
                                  { layer.geometry !== 'Point' && (
                                    <Grid item xs={1}>
                                      <Box
                                        style={{
                                          background: legend.color,
                                          height: 10,
                                          width: 10,
                                          borderRadius: '50%',
                                          marginRight: 5,
                                          borderStyle: 'solid',
                                          borderWidth: 1,
                                          borderColor: 'gray',
                                        }}
                                      />
                                    </Grid>
                                  )}
                                </Grid>
                                <Grid item xs={10}>
                                  <MuiLegendName noWrap>
                                    {legend.name || legend.value}
                                  </MuiLegendName>
                                </Grid>
                              </MuiGridItemLegend>
                            ))}
                          </Grid>
                        )}
                    </MuiGridItemContainer>
                  </React.Fragment>
                ))}
              </MuiGridContainerLegend>
            </MuiGridRoot>
          </Fade>
        )}
      </Popper>)}
    </>
  );
};

export default Legend;

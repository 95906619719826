import * as React from 'react';
import {
  Grid,
  IconButton,
  Link,
} from '@mui/material';
import { NavLink } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import AppsIcon from '@mui/icons-material/Apps';
import LayersIcon from '@mui/icons-material/Layers';
import WidgetsIcon from '@mui/icons-material/Widgets';
import PublicIcon from '@mui/icons-material/Public';
import SettingsIcon from '@material-ui/icons/Settings';

import { useAuth } from 'components/providers/AuthProvider';
import { useTranslation } from '../providers/TranslationProvider';

import {
  breadCrumbLastItemStyle,
  breadCrumbLocationStyle,
  btnIconSelectedStyle, btnIconStyle, iconSelectedStyle, iconStyle,
  MuiBreadcrumbs,
  MuiDivCommon
} from './styles/navigationMenu';
import { usePermissions } from 'components/hooks/usePermissions';
import { GLOBAL } from 'utils/constants';
import useScheme from "../../api/hooks/useScheme";

const NavigationMenu = ({ location, column: vertical }) => {

  const { t } = useTranslation();
  const { user, isAuthenticated } = useAuth();
  const locArray = location.pathname.split('/');
  const schemeId = locArray[6];
  const isMapView = locArray[4] === 'view';
  const { data, isLoading } = useScheme({scheme_id: schemeId, user_id: locArray[2], isAuthenticated: isAuthenticated, enabled: isMapView});
  const [scenarioName, setScenarioName] = React.useState('');

  const getLastElement = () => {
    if (locArray[6] === 'custom' && locArray[8]) return 5;
    if (locArray[6] === 'custom' && locArray[7]) return 4;
    if (locArray.includes('view')) return 3;
    if (locArray.includes('specification')) return 2;
    if (locArray[2]) return 1;
    if (locArray[1]) return 0;
    return null;
  };

  React.useEffect(() => {
    if(data && data.scheme){
      setScenarioName(data.scheme.name)
    }
  }, [data]);
  return (
    <Grid
      container
      direction={vertical ? GLOBAL.POSITION.COLUMN : GLOBAL.POSITION.ROW}
    >
      <MuiBreadcrumbs aria-label={GLOBAL.ARIALABEL.BREADCRUMB}>
        {(user.type === GLOBAL.ROLES.SUPERADMIN || user.type === GLOBAL.ROLES.ADMIN ||
        ( user.type !== GLOBAL.ROLES.SUPERADMIN && user.type !== GLOBAL.ROLES.ADMIN && user.non_admin_users_orgs_ids_access_permissions.length > 0 )) && (
          <MuiDivCommon>
            <IconButton
              component={NavLink}
              to={`${locArray[0]}/orgs`}
              style={
                getLastElement() === 0 && locArray[1] === 'orgs'
                  ? btnIconSelectedStyle
                  : btnIconStyle
              }
            >
              <AppsIcon
                style={
                  getLastElement() === 0 && locArray[1] === 'orgs'
                    ? iconSelectedStyle
                    : iconStyle
                }
              />
            </IconButton>
            <Link to={`${locArray[0]}/orgs`} style={breadCrumbLocationStyle} component={NavLink} underline='none'>
                { t('orgs') }
            </Link>
          </MuiDivCommon>
        )}
        {getLastElement() >= 1 && locArray[2] !== 'workspace' ? (
          <MuiDivCommon sx={ getLastElement() === 1 && breadCrumbLastItemStyle }>
            <IconButton
              component={NavLink}
              to={`${locArray.slice(0, 3).join('/')}/maps`}
              style={getLastElement() === 1 ? btnIconSelectedStyle : btnIconStyle}
            >
              <HomeIcon style={getLastElement() === 1 ? iconSelectedStyle : iconStyle} />
            </IconButton>
              <Link to={`${locArray.slice(0, 3).join('/')}/maps`} style={breadCrumbLocationStyle} component={NavLink} underline='none'>
                {t('home_btn')}
              </Link>
          </MuiDivCommon>
        ) : (
          locArray[2] === 'workspace' && (
            <MuiDivCommon style={ getLastElement() === 1 && breadCrumbLastItemStyle }>
              <IconButton
                component={NavLink}
                to={`${locArray.slice(0, 3).join('/')}/maps`}
                style={getLastElement() === 1 ? btnIconSelectedStyle : btnIconStyle}
              >
                <PersonIcon
                  style={getLastElement() === 1 ? iconSelectedStyle : iconStyle}
                />
              </IconButton>
              <Link to={`${locArray.slice(0, 3).join('/')}/maps`} style={breadCrumbLocationStyle} component={NavLink} underline='none'>
                {t('my_workspace')}
              </Link>
            </MuiDivCommon>
          )
        )}
        {getLastElement() === 2 && (
          <MuiDivCommon sx={ getLastElement() === 2 && breadCrumbLastItemStyle }>
            <IconButton
              color={GLOBAL.COLOR.SECONDARY}
              style={btnIconSelectedStyle}
              component={NavLink}
              to={`${location.pathname}`}
            >
              <SettingsIcon style={getLastElement() === 2 ? iconSelectedStyle : iconStyle} />
            </IconButton>
            <Link to={`${location.pathname}`} style={breadCrumbLocationStyle} component={NavLink} underline='none'>
                { t('setting') }
              </Link>
            </MuiDivCommon>
        )}
        {getLastElement() === 3 && (
          <MuiDivCommon sx={ getLastElement() === 3 && breadCrumbLastItemStyle }>
            <IconButton
              color={GLOBAL.COLOR.SECONDARY}
              style={btnIconSelectedStyle}
              component={NavLink}
              to={`${location.pathname}`}
            >
              <PublicIcon style={getLastElement() === 3 ? iconSelectedStyle : iconStyle} />
            </IconButton>
            <Link to={`${location.pathname}`} style={breadCrumbLocationStyle} component={NavLink} underline='none'>
                {scenarioName}
              </Link>
          </MuiDivCommon>
        )}
        {getLastElement() >= 4 && (
          <MuiDivCommon sx={ getLastElement() === 4 && breadCrumbLastItemStyle }>
            <IconButton
              color={GLOBAL.COLOR.SECONDARY}
              style={getLastElement() === 4 ? btnIconSelectedStyle : btnIconStyle}
              component={NavLink}
              to={`${locArray.slice(0, 8).join('/')}`}
            >
              <LayersIcon
                style={getLastElement() === 4 ? iconSelectedStyle : iconStyle}
              />
            </IconButton>
            <Link to={`${locArray.slice(0, 8).join('/')}`} style={breadCrumbLocationStyle} component={NavLink} underline='none'>
                {t('layer_view_tooltips')}
              </Link>
          </MuiDivCommon>
        )}
        {getLastElement() === 5 && (
          <MuiDivCommon sx={ getLastElement() === 5 && breadCrumbLastItemStyle }>
            <IconButton
              color={GLOBAL.COLOR.SECONDARY}
              style={btnIconSelectedStyle}
              component={NavLink}
              to={`${locArray.slice(0, 9).join('/')}`}
            >
              <WidgetsIcon
                style={getLastElement() === 5 ? iconSelectedStyle : iconStyle}
              />
            </IconButton>
            <Link to={`${locArray.slice(0, 9).join('/')}`} style={breadCrumbLocationStyle} component={NavLink} underline='none'>
                {t('widget_view_tooltips')}
              </Link>
          </MuiDivCommon>
        )}
      </MuiBreadcrumbs>
    </Grid>
  );
};
export default NavigationMenu;

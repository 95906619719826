import * as React from 'react';
import {
  Grid,
  Box,
  Divider,
  MenuItem
} from '@mui/material';
import { useForm, FormProvider } from 'react-hook-form';
import Modal from 'components/common/Modal';
import Toast from 'components/common/Toast';
import { TextField, SelectField, SliderFieldCustom } from 'components/common/ReactHooksFormFields';
import TypographyWithInfoIconTooltip from 'components/common/TypographyWithInfoIconTooltip';
import { setRecomputedRecommendationsWidgets, setWidgetFiltersForCurrentOffer, setWidgetFiltersForTargetDemand } from 'store/appSlice';

import { useTranslation } from 'components/providers/TranslationProvider';
import IOSSwitch from 'components/common/IOSSwitch';
import CustomSkeleton from 'components/common/CustomSkeleton';
import { useDispatch, useSelector } from 'react-redux';
import {
  getPolygonWidgetsWithFilters,
  getWidgetsWithFiltersByLayerId
} from 'utils/supportComponents';
import {
  customSwitchLabel,
  customSwitchLabelStyle,
  marginStyle,
  MuiContainer,
  MuiDemandLabel,
  MuiRecommendationsExpansionCapacityLabel,
  MuiRecommendationsExpansionCostLabel,
  MuiRecommendationsLabel, MuiSwitchLabel,
  MuiSwitchTypography,
  MuiTypography,
  MuiVariableLabel,
  MuiVariableNameCommon
} from '../styles/generateRecommendationsFormModal';
import {
  MuiBtnAccept,
  MuiBtnCancel
} from '../styles/computeRecommendationFormModal';
import useDataSetColumns from 'api/hooks/useDataSetColumns';
import useFilterQueryBuilder from 'api/hooks/useFilterQueryBuilder';
import {useLocation} from "react-router-dom";

const GenerateRecommendationsFormModal = ({ open, onClose, defaultValues,
                                            dataForRecom, setDataForRecom  }) => {
  const { t } = useTranslation();

  const [openToast, setOpenToast] = React.useState(false);
  const [error, setError] = React.useState('');
  const selectedWidgetId = useSelector((state) => state.app.selectWidgetId);
  const selectedWidgetEntity = useSelector((state) => ( state.app.recomputedRecommendationsWidgets.find(w => w.id === selectedWidgetId) || state.app.widgets.find(w => w.id === selectedWidgetId)));
  const dispatch = useDispatch();
  const sources = useSelector((state)=> state.carto.dataSources);
  const layers = useSelector((state)=> state.app.layers);
  const widgets = useSelector((state)=> state.app.widgets);
  const [targetDemandFilter, setTargetDemandFilter] = React.useState('');
  const [currentOfferFilter, setCurrentOfferFilter] = React.useState('');
  const isExpansionCapacityVisible = !!selectedWidgetEntity?.params?.fieldsToShow?.isExpansionCapacityVisible;
  const isExpansionCostVisible = !!selectedWidgetEntity?.params?.fieldsToShow?.isExpansionCostVisible;
  const filterPolygon = useSelector((state) => state.app.listFilterPolygonData);

  const {
    data: demandDatasetColumns,
    isSuccess: demandDatasetColumnsIsSuccess
  } = useDataSetColumns(
    selectedWidgetEntity.params.demandDatasetName,
    'NUMERICAL'
  );

  const queryBuilder = useFilterQueryBuilder({ operator: dataForRecom.operator });

  const getDefaultValues = () => {
    return {
      budget: selectedWidgetEntity?.params?.budget ? selectedWidgetEntity?.params?.budget :  1,
      expanxionCapacity: selectedWidgetEntity?.params?.expansion_capacity ? selectedWidgetEntity?.params?.expansion_capacity : selectedWidgetEntity?.expanxionCapacity,
      expanxionCost: selectedWidgetEntity?.params?.expansion_cost ? selectedWidgetEntity?.params?.expansion_cost :  selectedWidgetEntity?.expanxionCost,
      welfareVariable:  selectedWidgetEntity?.params?.w_var ? selectedWidgetEntity?.params?.w_var : selectedWidgetEntity?.params?.welfareVariable,
      densityVariable: selectedWidgetEntity?.params?.quant_var ? selectedWidgetEntity?.params?.quant_var : selectedWidgetEntity?.params?.densityVariable,
      isWelfareFormulaSelected: selectedWidgetEntity?.params?.is_welfare_formula_selected !== null && selectedWidgetEntity?.params?.is_welfare_formula_selected !== undefined ? selectedWidgetEntity?.params?.is_welfare_formula_selected : !!selectedWidgetEntity?.params?.isFormulaFieldSelected,
      welfareFormula: selectedWidgetEntity?.params?.custom_w_var ? selectedWidgetEntity?.params?.custom_w_var : selectedWidgetEntity?.params?.formulaWelfare,
      recommendedExpansion: selectedWidgetEntity?.params?.recommended_expansion ? selectedWidgetEntity?.params?.recommended_expansion : selectedWidgetEntity?.params?.recommendedExpansion,
      coverage_type: selectedWidgetEntity?.params?.coverage_type ? selectedWidgetEntity?.params?.coverage_type : false
    }
  };

  const methods = useForm({
    defaultValues: getDefaultValues(),
    mode: 'all',
  });

  const {
    handleSubmit,
    formState: { errors, isValid, isDirty },
    watch,
    setValue,
  } = methods;

  const recommendedExpansion = watch('recommendedExpansion');
  const budget = watch('budget');
  const expanxionCapacity = watch('expanxionCapacity');
  const expanxionCost = watch('expanxionCost');
  const isWelfareFormulaSelected = watch('isWelfareFormulaSelected');
  const welfareFormula = watch('welfareFormula');
  const welfareVariable = watch('welfareVariable');
  const densityVariable = watch('densityVariable');
  const coverageType = watch('coverageType');

  React.useEffect(()=> {
    setValue('coverageType', selectedWidgetEntity?.params?.coverage_type ? selectedWidgetEntity?.params?.coverage_type : false)
  },[])

  const handleCloseToast = () => {
    setOpenToast(false);
  };

  React.useEffect(() => {
    const demandQuery = queryBuilder(selectedWidgetEntity.layer);
    let currentOfferQuery = '';
    if (selectedWidgetEntity.params.isOfferSelected) {
      currentOfferQuery = queryBuilder(selectedWidgetEntity.params.currentOfferLayerName);
    }
    if (demandQuery) {
      setTargetDemandFilter(demandQuery);
    } else {
      setTargetDemandFilter('');
    }
    if (currentOfferQuery && selectedWidgetEntity.params.isOfferSelected) {
      setCurrentOfferFilter(currentOfferQuery);
    } else {
      setCurrentOfferFilter('');
    }
  }, [sources, selectedWidgetId, queryBuilder]);

  const generateAndUpdateRecommendationsData = () => {
    setDataForRecom({
      ...dataForRecom,
      recommendedExpansion,
      budget,
      expanxionCapacity,
      expanxionCost,
      isWelfareFormulaSelected,
      welfareFormula,
      welfareVariable,
      densityVariable,
      coverageType
    });
    dataForRecom.handleGenerateRecommendations(budget, densityVariable, recommendedExpansion, expanxionCost, expanxionCapacity, isWelfareFormulaSelected, welfareFormula, welfareVariable, coverageType, targetDemandFilter, currentOfferFilter)

    const updatedWidget = {
      id: selectedWidgetEntity.id,
      layer: selectedWidgetEntity.layer,
      params: {
        ...selectedWidgetEntity.params,
      },
    };
    updatedWidget.params.budget = budget;
    updatedWidget.params.expansion_capacity = expanxionCapacity;
    updatedWidget.params.expansion_cost = expanxionCost;
    updatedWidget.params.w_var = welfareVariable;
    updatedWidget.params.quant_var = densityVariable;
    updatedWidget.params.custom_w_var = welfareFormula;
    updatedWidget.recommended_expansion = recommendedExpansion
    updatedWidget.params.is_welfare_formula_selected = isWelfareFormulaSelected;
    updatedWidget.params.coverage_type = coverageType;
    dispatch(setRecomputedRecommendationsWidgets( updatedWidget ));
    const widgetFiltersForCurrentOfferResp = getWidgetsWithFiltersByLayerId( updatedWidget.params.currentOfferLayerName, sources, layers, widgets );

    let widgetFiltersForTargetDemandResp = getWidgetsWithFiltersByLayerId( selectedWidgetEntity.layer, sources, layers, widgets );
    widgetFiltersForTargetDemandResp.push(...getPolygonWidgetsWithFilters(widgets ,filterPolygon ));
    dispatch(setWidgetFiltersForTargetDemand(
      widgetFiltersForTargetDemandResp.map(obj => ({ ...obj }))
    ));

    dispatch(setWidgetFiltersForCurrentOffer(
      widgetFiltersForCurrentOfferResp.map(obj => ({ ...obj }))
    ));
  }

  const formGenerateRecommendations = (
    <MuiContainer display='flex'>
      <Toast
        message={error}
        handleClose={handleCloseToast}
        severity='error'
        horizontal='center'
        vertical='top'
        open={openToast}
      />
      <Grid item xs={12}>
        <Box justifyContent="space-between" display="flex">
          <MuiTypography variant="body1">
            {dataForRecom?.widget?.params?.customLabels?.recommendationsBudget || t('budget')}:
          </MuiTypography>
          <TypographyWithInfoIconTooltip >
            { t('recommendations_budget_info') }
          </TypographyWithInfoIconTooltip>
        </Box>
      </Grid>
      <Grid item xs={12}>
          <Box justifyContent="space-between" display="flex">
          <SliderFieldCustom
            name='budget'
            min={1}
            step={1}
            valueLabelDisplay='auto'
            max={500}
            custom
            gridSliderInputColumn= {5}
            gridSliderColumn= {7}
          />
          <MuiVariableNameCommon variant='caption'>
            {`(${ t('unit').toLowerCase()})`}
          </MuiVariableNameCommon>
          </Box>
          <Divider style={{ marginTop: '20px', marginBottom: '20px' }}/>
      </Grid>
      { !!defaultValues?.isOfferSelected && ( isExpansionCapacityVisible || isExpansionCostVisible ) && (<>
      <Grid item xs={12}>
        <MuiRecommendationsLabel variant="body1">
            {t('recommendations_recommended_expansion')}
        </MuiRecommendationsLabel>
      </Grid>
      <Grid item xs={12}>
        <Box display="inline-flex">
          <IOSSwitch
            labelPlacement="start"
            labelClasses={ customSwitchLabelStyle }
            checked={recommendedExpansion}
            onChange={(e) => setValue('recommendedExpansion', e.target.checked)}
          />
          <MuiSwitchTypography variant='caption'>
            { t('no_yes') }
          </MuiSwitchTypography>
          <TypographyWithInfoIconTooltip className={ marginStyle }>
            { t('recommendations_expansion_capacity_info') }
          </TypographyWithInfoIconTooltip>
        </Box>
      </Grid>
      {recommendedExpansion && isExpansionCapacityVisible ? (<Grid item xs={12}>
          <Box display={'flex'} flexDirection={'column'}>
          <MuiRecommendationsExpansionCapacityLabel>
          { defaultValues?.customLabels?.recommendationsExpansionCapacity || t('recommendations_expansion_capacity') }:
          </MuiRecommendationsExpansionCapacityLabel>
          </Box>
          <Box justifyContent="space-between" display="flex">
          <SliderFieldCustom
            name='expanxionCapacity'
            step={1}
            min={1}
            max={500}
            valueLabelDisplay='auto'
            custom
            gridSliderInputColumn= {5}
            gridSliderColumn= {7}
          />
          <MuiVariableLabel variant='caption'>
            (max_expansion_capacity)
          </MuiVariableLabel>
          </Box>
      </Grid>) : null}
      {recommendedExpansion && isExpansionCostVisible ? (<Grid item xs={12}>
          <Box display={'flex'} flexDirection={'column'}>
          <MuiRecommendationsExpansionCostLabel>
          { defaultValues?.customLabels?.recommendationsExpansionCost || t('recommendations_expansion_cost') }:
          </MuiRecommendationsExpansionCostLabel>
          </Box>
          <Box justifyContent="space-between" display="flex">
          <SliderFieldCustom
            name='expanxionCost'
            step={1}
            min={1}
            max={500}
            valueLabelDisplay='auto'
            custom
            gridSliderInputColumn= {5}
            gridSliderColumn= {7}
          />
          <MuiVariableLabel variant='caption'>
          {`(${ t('unit').toLowerCase()})`}
          </MuiVariableLabel>
          </Box>
      </Grid>):null}
      <Divider style={{ marginTop: '20px', marginBottom: '20px' }}/>
      </>)}
      <Grid item xs={12}>
          <Box display={'flex'} flexDirection={'column'}>
          <MuiDemandLabel variant="body2">
            {t('demmand_config')}
          </MuiDemandLabel>
          </Box>
          <Box justifyContent="space-between" display="flex">
          { demandDatasetColumnsIsSuccess ? (
                    <SelectField
                      name='densityVariable'
                      variant='outlined'
                      label={  dataForRecom?.widget?.params?.customLabels?.recommendationsDemandDensity || t('recommendations_demand_density')}
                      fullWidth
                      sx={{paddingBottom: 16}}
                    >
                      <Divider light value=' ' />
                      { demandDatasetColumns?.map((ddc) => (
                        <MenuItem
                          key={ddc}
                          value={ddc}
                          selected={ densityVariable === ddc}
                        >
                          {ddc}
                        </MenuItem>
                      )) }
                    </SelectField>
                    ) : (
                      <CustomSkeleton />
                    )}
          <TypographyWithInfoIconTooltip className={ marginStyle } >
            { t('recommendations_demand_density_info') }
          </TypographyWithInfoIconTooltip>
          </Box>
      </Grid>
      <Grid item xs={12}>
        <Box justifyContent="space-between" display="flex" style={{marginBottom: '8px'}}>
          <MuiTypography variant='caption'>
            { t('recommendations_specify_welfare_formula') }
          </MuiTypography>
          <IOSSwitch
            labelPlacement="start"
            labelClasses={ customSwitchLabelStyle }
            checked={isWelfareFormulaSelected}
            onChange={(e) => setValue('isWelfareFormulaSelected', e.target.checked)}
            name="isWelfareFormulaSelected"
          />
          <MuiSwitchLabel variant='caption'>
            { t('no_yes') }
          </MuiSwitchLabel>
          <TypographyWithInfoIconTooltip className={ marginStyle }>
            { t('recommendations_priorization_variable_info') }
          </TypographyWithInfoIconTooltip>
        </Box>
      </Grid>
      <Grid item xs={12}>
        {!isWelfareFormulaSelected && <>
          {demandDatasetColumnsIsSuccess ? (
            <div>
              <SelectField
                name='welfareVariable'
                variant='outlined'
                label={dataForRecom?.widget?.params?.customLabels?.variableWelfare || t('variable_welfare')}
                fullWidth
                style={{ paddingBottom: '5px' }}
              >
                <Divider light value=' ' />
                {demandDatasetColumns?.map((ddc) => (
                  <MenuItem key={ddc} value={ddc}>
                    {ddc}
                  </MenuItem>
                ))}
              </SelectField>
            </div>
          ) : (
            <CustomSkeleton />
          )
          }</>}
        {isWelfareFormulaSelected && (
          <div>
            <TextField
              name='welfareFormula'
              margin='dense'
              type='text'
              label={dataForRecom?.widget?.params?.customLabels?.recommendationsFormulaPriorizationVariable || t('recommendations_formula_priorization_variable')}
              variant='outlined'
              fullWidth
            />
          </div>
        )}
      </Grid>
      <Grid item xs={12}>
        <Box justifyContent="space-between" display="flex" style={{marginBottom: '8px'}}>
          <MuiTypography variant='caption'>
            { t('recommendations_specify_coverage_type') }
          </MuiTypography>
          <IOSSwitch
            labelPlacement="start"
            labelClasses={ customSwitchLabel }
            checked={coverageType}
            onChange={(e) => setValue('coverageType', e.target.checked)}
            name="coverageType"
            sx={{ml: '8px'}}
          />
          <MuiTypography variant='caption'>
            { t('isochrone_circle') }
          </MuiTypography>
        </Box>
      </Grid>
    </MuiContainer>
  );

  const actions = (
    <>
      <MuiBtnCancel onClick={onClose} variant='text'>
        {t('cancel_btn')}
      </MuiBtnCancel>
      <MuiBtnAccept
        type='submit'
        variant='outlined'
        onClick={ () => generateAndUpdateRecommendationsData() }
      >
        { t('widget_button_generate_label') }
      </MuiBtnAccept>
    </>
  );

  return (
    <FormProvider {...methods}>
      <form>
        <Modal
          open={open}
          title={ t('generate_recommendations_modal_header') }
          actions={actions}
          widthInPixels={480}
        >
          {formGenerateRecommendations}
        </Modal>
      </form>
    </FormProvider>
  );
};

export default GenerateRecommendationsFormModal;

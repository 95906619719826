import * as React from 'react';
import { useTranslation } from '../../../../../../../providers/TranslationProvider';
import jsep from 'jsep';
import { ButtonPanel } from './ButtonPanel';

import {
  Autocomplete,
  Chip, IconButton,
  TextField,
  useTheme, Grid, InputBase, Box, InputAdornment, MenuItem, Menu, Popper, Paper,
} from '@mui/material';
import {
  closeIconStyle, getChipStyle,
  listElementsStyle,
  MuiGridCalculator, MuiGridExpression
} from './styles/formulaGenerator';
import { MuiCheckCircleIcon, MuiRefreshIcon } from '../styles/caseGenerator';
import CloseIcon from '@mui/icons-material/Close';
import Stat from '../stat/Stat';
import List from '../list/List';
import {useSelector} from "react-redux";
import axios from '../../../../../../../../api/axios/axiosCartoInstance';
import apiPaths from "../../../../../../../../api/apiPaths";
import { GLOBAL, languageMap } from '../../../../../../../../utils/constants';
import {status200} from "../../../../../../../../api/status.utils";
import ToastValidation from "../../../../../../../common/ToastValidation";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {parseFormula} from "../../../../../../../../utils/supportComponents";
import { lighten } from '@mui/material/styles';

export  function getExpressionType(type) {
  switch (type) {
    case 'list':
      return `?expression_type=ANY`
    case 'category':
      return `?expression_type=LOGIC`
    case 'category_case':
      return `?expression_type=ANY`
    case 'recos_math':
      return `?expression_type=MATH`
    case 'recos_logic':
      return `?expression_type=LOGIC`
    default:
      return `?expression_type=MATH`;
  }
}
export function FormulaGenerator({
    methodsForFormula, variablesForFormula, columnsList, columnsOrderStatistics,
    setColumnListOrderStats, setOpenToast, setSeverity, setMessageToast, setColumnListOrder,
    setIsFormulaValidate, formulaErrors, setFormulaErrors, formula, setFormula, tableName}) {

  const [expressionFields, setExpressionFields] = React.useState([1])
  const [selectedExpresionField, setSelectedExpresionField] = React.useState(1)
  const textInputRefs = React.useRef({});
  const [selectedValues, setSelectedValues] = React.useState([]);
  const [inputValue, setInputValue] = React.useState('');
  const [, setAnchorEl] = React.useState(null);
  const widgetsParameters = useSelector((state) => state.app.widgets.filter(widget => widget.type === 'parameters'));
  const [validating, setValidating] = React.useState(false);
  const [message, setMessage] = React.useState();
  const [open, setOpen] = React.useState(false);
  const [idInstanceForm, setIdInstanceForm] = React.useState(-1)

  const {t} = useTranslation();
  const theme = useTheme();
  const selectedFormulaObject = formula.find((item) => item.id === selectedExpresionField);
  const selectedFormula = selectedFormulaObject ? selectedFormulaObject.formula : '';

  const updateFormula = (id, newFormula) => {
    setFormula(prevFormulas => prevFormulas.map(item =>
      item.id === id ? { ...item, formula: newFormula } : item
    ));
    setFormulaErrors(prevErrors => ({ ...prevErrors, [id]: { error: false, errorMessage: '' }}));
  };

const [methods,, setFormulaWithAlias ] = methodsForFormula
const [columns, action, calculator, errors, datasetColumn,
       widget, formulaWithAlias, type, alias, columnsListOrder, evaluate, isTargetDemandsFieldSelected, isFormulaFieldSelected] = variablesForFormula
const [, setIsWide] = React.useState(window.innerWidth > 1300);
const btnValidateRef = React.useRef(null);
const [editingChipIndex, setEditingChipIndex] = React.useState(null);
const [editChipValue, setEditChipValue] = React.useState('');
const inputRef = React.useRef(null);
const boxRef = React.useRef(null);
const [menuOpen, setMenuOpen] = React.useState(false);
const [anchorPosition, setAnchorPosition] = React.useState(null);
const [filteredOptions, setFilteredOptions] = React.useState(columns);

const errorOperator = [
    '++', '--', '+-', '-+', '*+', '+*', '/+', '+/',
    '**', '*/', '/*', '//', '/-', '-/', '*-', '-*',
     '<<', '>>', '&&', '||',
    '%%', '%*', '*%', '!!', '?:', ':?', '->', '<>'
  ];

const specialCases = new Set(['ceil', 'floor', 'log', 'abs', 'min', 'max', 'pow', 'sqrt']);
const [tempValue, setTempValue] = React.useState('');

React.useEffect(() => {
    const handleResize = () => {
      setIsWide(window.innerWidth > 1300);
    };
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

const addFormInListOrStats = (alias) => {
    if(type === 'list'){
      setColumnListOrder(prevOrder => [
        ...prevOrder,
        {
          name: alias.match(/^(formula\d+)_/) ? alias.match(/^(formula\d+)_/)[1] : alias,
          field: alias,
          visible: true,
          id: Date.now().toString(36) + Math.random().toString(36),
        },
      ])
    }

    if(type === 'statistics') {
      setColumnListOrderStats(prevOrder => [
        ...prevOrder,
        {
          name: alias.match(/^(formula\d+)_/) ? alias.match(/^(formula\d+)_/)[1] : alias,
          field: alias,
          visible: true,
          id: Date.now().toString(36) + Math.random().toString(36),
        },
      ])
    }

    setSelectedValues([])
  }

const updateFormInListOrStats = () => {
    const valueForm = selectedValues.join('');

    if(type === 'list'){
      setColumnListOrder((prevColumnList) =>
        prevColumnList.map((item) =>
          item.id === idInstanceForm ? { ...item, formula: valueForm } : item
        )
      );
    }

    if(type === 'statistics') {
      setColumnListOrderStats((prevColumnStats) =>
        prevColumnStats.map((item) =>
          item.id === idInstanceForm ? { ...item, formula: valueForm } : item
        )
      );
    }

    setFormulaWithAlias((prevFormInstance) =>
      prevFormInstance.map((item) =>
        item.id === idInstanceForm ? { ...item, formula: valueForm } : item
      )
    );

    setSelectedValues([])
    setIdInstanceForm(-1)
  }

const createAlias = () => {
  if(type === 'histogram') {
    const uniquePart = (Date.now().toString(36) + Math.random().toString(36).substr(2, 5)).slice(0, 8);
    const alias =  `formula_${uniquePart}`;
    setFormulaWithAlias([{formula: formula[0].formula, alias: alias}])
    methods.setValue('datasetColumn', formula[0].formula);

    return
  } else if(type === 'recommendations') {
    setFormula([{ id: 1, formula: formula[0].formula }])
    return
  }
  // Step 1: Find the highest numeric suffix in existing aliases
  const maxExistingAliasNumber = formulaWithAlias.reduce((max, item) => {
    const match = item.alias.match(/formula(\d+)/); // Assuming the alias format is "formula[number]"
    const num = match ? parseInt(match[1], 10) : 0;
    return Math.max(max, num);
  }, 0);
  // Step 2: Start counting from the next number after the highest found
  let nextAliasNumber = maxExistingAliasNumber + 1;

  const updatedFormulaWithAlias = formula.map((item, index) => {
    // Use nextAliasNumber to generate the next alias
    const uniquePart = (Date.now().toString(36) + Math.random().toString(36).substr(2, 5)).slice(0, 8);
    const alias = `formula${nextAliasNumber++}_${uniquePart}`;
    idInstanceForm === -1 ? addFormInListOrStats(alias) : updateFormInListOrStats()
    return { ...item, alias };
  });

  if(idInstanceForm === -1) setFormulaWithAlias([...formulaWithAlias, ...updatedFormulaWithAlias])
};

const formular = (e) => {
  let content;
  const textContent = e.target.textContent;

  if (textContent === 'MIN') {
    content = 'LEAST';
  } else if (textContent === 'MAX') {
    content = 'GREATEST';
  } else {
    content = textContent;
  }

  setSelectedValues((prevSelectedValues) => [...prevSelectedValues, content]);
  let updatedFormula = selectedFormula;

  if (specialCases.has(content)) {
    // Add function with empty parentheses
    updatedFormula = `${updatedFormula}${content}(`;
  }
  else{
    // Normal case, just append the content
    updatedFormula = `${updatedFormula}${content}`;
  }

  updateFormula(selectedExpresionField, updatedFormula);
};

 // Generate regex patterns for adjacent function names
const generateAdjacentFunctionPatterns = (functions) => {
  return functions.reduce((patterns, func1) => {
    const newPatterns = functions.map(func2 => new RegExp(`${func1}\\s*\\(\\s*\\)\\s*${func2}`, 'gi'));
    return patterns.concat(newPatterns);
  }, []);
};

function setExpressionBasedInHistogramParams() {
  const field = widget.params.field;
  const alias = widget.params.alias;


  const newExpressionFieldId = 1;
  setExpressionFields([newExpressionFieldId]);


  setFormula([{ id: newExpressionFieldId, formula: field }]);


  setFormulaWithAlias([{ formula: field, alias: alias }]);

  setSelectedExpresionField(newExpressionFieldId);

}

function setExpressionsBasedWidgetParams(widgetParams) {
  const listItems = widgetParams || [];

  const formulaObjects = listItems.map(item => ({
    id: item.id,
    formula: item.field,
    alias: item.alias,
  }));

  setFormulaWithAlias(formulaObjects);
}

  const verificationExpression = (event) => {
    let allFormulasValid = true;
    const newFormulaErrors = {};

    formula.forEach(formulaItem => {
      try {
        const currentFormula = formulaItem.formula;
        const parameters = widgetsParameters.reduce((acc, widget) => {
          widget.params.parametersCatalog.forEach(param => {
            acc[param.propName] = param.value;
          });
          return acc;
        }, {});
        setValidating(true)
        const expression_type = getExpressionType(evaluate);
        axios.post(`${apiPaths.validateExpression}${expression_type}`,{
          'expression': currentFormula,
          'table_name': tableName,
          'parametersCatalog': parameters,
          'lang': languageMap[localStorage.getItem('lng')]
        }, status200).then((resp) => {
          if (resp.data.status === 'success') {
            createAlias()
            setValidating(false);
            setMessage(resp.data);
            setOpen(true)
            if(type === 'recommendations') setIsFormulaValidate(true)
          } else {
            setMessage(resp.data);
            setValidating(false);
            setOpen(true);
            if(type === 'recommendations') setIsFormulaValidate(true)
          }
        }).catch((error) => {
          setValidating(false);
          setOpenToast(true);
          setMessageToast(error.response.data.message || error.response.data.error);
          setSeverity('error');
        });

        // Regex to find at least one operation
        const regex = /(\bstart with\b|\bbetween\b|==|!=|\bceil\b|\blog\b|\babs\b|\bmin\b|\bmax\b|\bpow\b|\bsqrt\b|\bfloor\b|[+\-/*]|>|<|>=|<=)/g;
        const found = currentFormula.match(regex);
        const isInvalidFormula = !found || found.length === 0 || /[+\-/*]$|start with$|between$|ceil$|log$|abs$|min$|max$|pow$|sqrt$|floor$/.test(currentFormula);

        if (isInvalidFormula) {
          newFormulaErrors[formulaItem.id] = { error: true, errorMessage: t('valid_mathematical_operation') };
          allFormulasValid = false;
        } else {
          errorOperator.forEach(item => {
            if (currentFormula.includes(item)) {
              newFormulaErrors[formulaItem.id] = { error: true, errorMessage: t('valid_mathematical_operation') };
              allFormulasValid = false;
            }
          });

          const adjacentFunctionPatterns = generateAdjacentFunctionPatterns(['ceil', 'log', 'abs', 'min', 'max', 'pow', 'sqrt', 'floor']);

          adjacentFunctionPatterns.forEach(pattern => {
            if (pattern.test(currentFormula)) {
              newFormulaErrors[formulaItem.id] = { error: true, errorMessage: `Invalid adjacent functions found: ${pattern}` };
              allFormulasValid = false;
            }
          });

          jsep(currentFormula); // Parse current formula
        }
      } catch (e) {
        console.error('Parsing error:', e.message);
        newFormulaErrors[formulaItem.id] = { error: true, errorMessage: e.message };
        allFormulasValid = false;
      }
    });

    setFormulaErrors(newFormulaErrors);
    if (message?.status === 'success') setAnchorEl(event.currentTarget)

    return allFormulasValid;
  };

const partialFormulaValidation = () => {
  try {
      const regex = /[+\-/*]/g;
      const found = formula.match(regex);
      if (!found || found.length <= 1)  return false;

      jsep(formula);
      return true;
  } catch (e) {
      return false;
  }
};

const handleChange = (event, values) => {
  setSelectedValues(values);
  setTempValue('');
  if (typeof setIsFormulaValidate === 'function') {
    setIsFormulaValidate(false);
  }
}

const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
    setMessage(null)
  };

const handleClear  = () => {
  setFormula([{id: 1, formula: ''}]);
  setSelectedValues([]);
  setTempValue('');
  };

const deleteLastElement = () =>  {
  setSelectedValues((prevSelectedValues) => {
    return prevSelectedValues.slice(0, -1);
  });
}

React.useEffect(() => {
  if (type !== 'histogram' && type !== 'recommendations' && type !== 'statistics' && type !== 'list') return;

  const currentFormula = action === 'update' && type === 'histogram'
                       ? methods.getValues('datasetColumn') : formula[0].formula !== '' ? formula[0].formula : []

  if(currentFormula !== '') {
    const newValue = parseFormula(currentFormula, columns)
    setSelectedValues(newValue)
  }
}, [type, action])

React.useEffect(()=>{
    if(action === 'create') return
    if(type === 'histogram') {
      setExpressionBasedInHistogramParams()
    }
    else if(type === 'list'){
      setExpressionsBasedWidgetParams(widget.params.list)
    }
    else if(type === 'statistics'){
      setExpressionsBasedWidgetParams(widget.params.fields)
    }
  }, [ widget, action])

React.useEffect(() => {
  if (action === 'update' && !calculator) {

      // If the dataset is a formula, iterate over each formula instance and clear its value.
    if ( type !== 'recommendations' ) {
      formula.forEach((item) => {
        methods.setValue(`datasetColumn`, item.formula);
      });
    }
  }
  else if(type === 'category' &&  formulaWithAlias.formula !== '' ){
   methods.setValue('datasetColumn', formulaWithAlias.formula)
  }
   else if ( calculator && formulaWithAlias.length > 0 && formulaWithAlias[0].formula !== '' )  {

    // Update only the selected formula if the calculator is being used.
    if ( type !== 'recommendations' ) {
      formulaWithAlias.map((item) => {
        const updatedFormula = decodeURIComponent(item.formula || '');
        methods.setValue(`datasetColumn`, updatedFormula);
      });
    }
  }
  if (action === 'update' && !!isFormulaFieldSelected && formula[0].formula === '') {
    setSelectedValues([widget?.params.formulaWelfare]);
  }
  if (action === 'update' && !!isTargetDemandsFieldSelected && formula[0].formula === '') {
    setSelectedValues([widget?.params.targetDemands]);
  }
}, [action, calculator, formulaWithAlias, datasetColumn, errors, isTargetDemandsFieldSelected, isFormulaFieldSelected, widget?.params?.formulaWelfare, widget?.params?.targetDemands]);

React.useEffect(() => {
  if (formula &&formula !== '' && partialFormulaValidation()) {
    if ( type !== 'recommendations' ) {
      methods.setValue('datasetColumn', formula === decodeURIComponent(formula));
    }
  }
}, [formula]);

React.useEffect(() => {
    const selectedValuesString = selectedValues.join('');
    if(selectedValuesString){
      setFormula((prevFormula) =>
        prevFormula.map((item) =>
          item.id === 1 ? { ...item, formula: selectedValuesString } : item
        )
      );
    }
  }, [selectedValues]);

  const handleInputChange = (event, newInputValue) => {
    if (editingChipIndex !== null) {
      setEditChipValue((prev => prev + newInputValue));
    }else if (newInputValue && !columns.includes(newInputValue)) {
      setTempValue((prev => prev + newInputValue));
    } else {
      setTempValue('');
      setInputValue(newInputValue);
    }
  };
  React.useEffect(() => {
    if (tempValue !== '') {
      const splitValues = tempValue.split(/[\s*+\-/]+/);
      const lastWord = splitValues[splitValues.length - 1];
      filterOptions(lastWord);
    }else if (editChipValue !== ''){
      const splitValues = editChipValue.split(/[\s*+\-/]+/);
      const lastWord = splitValues[splitValues.length - 1];
      filterOptions(lastWord);
    }
  }, [tempValue, editChipValue])
  const handleConfirmValue = () => {
    if (tempValue !== '') {
      setSelectedValues([...selectedValues, tempValue]);
      setTempValue('');
    }
  };
  const handleKeyDown = (event) => {
    const allowedKeys = /^[a-zA-Z0-9=!@#$%^&*()_+{}\[\]:;"'<>,.?/\\|~` -]$/;
    if (event.ctrlKey && event.key === "v") {
      return;
    }
    if (!allowedKeys.test(event.key) && event.key !== 'Backspace' || event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
    }
    if (event.key === 'Backspace') {
      if (tempValue !== '') {
        setTempValue((prev => prev.slice(0, -1)));
        event.preventDefault();
        event.stopPropagation();
      }else if (editingChipIndex !== null) {
        setEditChipValue((prev => prev.slice(0, -1)));
        event.preventDefault();
        event.stopPropagation();
      }else if (selectedValues.length > 0) {
        handleChange(event, selectedValues.slice(0, -1));
      }
    }else if (allowedKeys.test(event.key)) {
      handleInputChange(null , event.key)
      event.preventDefault();
      event.stopPropagation();
    }
  };
  const filterOptions = (input) => {
    if (input.length === 0) {
      return;
    }
    const escapedString = input.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
    const regex = new RegExp(escapedString, "i");
    const filtered = columns.filter(option => regex.test(option));
    setFilteredOptions(filtered);
  };
  const startEditingChip = (index, value) => {
    setEditingChipIndex(index);
    setEditChipValue(value);
    setMenuOpen(true);
    setTimeout(() => {
      if(inputRef.current){
        inputRef.current.focus()
        inputRef.current.setSelectionRange(value.length, value.length);
      }
      }, 100);
  };
  const saveEditedChip = (event) => {
    const updatedValues = [...selectedValues];
    updatedValues[editingChipIndex] = editChipValue;
    setSelectedValues([...updatedValues]);
    setEditingChipIndex(null);
    setEditChipValue('');
    inputRef.current = null;
    event.preventDefault();
    event.stopPropagation();
  };
  const handlePaste = (event) => {
    event.preventDefault();
    const pasteText = event.clipboardData.getData("Text");
    if (!pasteText.trim()) return;
    setTempValue(pasteText.trim());
  };
  const handleOpenMenu = () => {
    if (boxRef.current) {
      const rect = boxRef.current.getBoundingClientRect();
      setAnchorPosition({ top: rect.bottom, left: rect.left, width: rect.width });
      setMenuOpen(true);
      setTimeout(() => {
        inputRef.current?.focus();
      }, 0);
    }
  };
  const handleAddChip = (option) => {
    setSelectedValues((prev) => [...prev, option]);
    setTempValue('');
  };
  const closeMenu = () => {
    setMenuOpen(false);
    setFilteredOptions(columns)
  };

  return(
  <>
    <Grid container>
      <MuiGridExpression item xs={12}>
        {expressionFields.map((i) => (
          <React.Fragment key={i.id}>
            <Box
              ref={boxRef}
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 1,
                alignItems: "center",
                border: `1px solid ${lighten(theme.palette.primary.main , 0.1)}`,
                p: 1 ,
                borderRadius: '4px',
                minWidth: '910px'
              }}
            >
              {/* Renderiza los chips guardados */}
              {selectedValues.map((chip, index) => (
                <Chip
                  key={index}
                  label={
                    editingChipIndex === index ? (
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <InputBase
                          value={editChipValue}
                          variant="standard"
                          inputRef={inputRef}
                          onKeyDown={handleKeyDown}
                          style={{fontFamily: 'Montserrat', fontWeight: 400, fontSize: 16 , width: "auto"}}
                        />
                        <IconButton onClick={saveEditedChip} size="small">
                          <CheckCircleOutlineIcon />
                        </IconButton>
                      </Box>
                    ) : (
                      chip
                    )}
                  variant="outlined"
                  sx={getChipStyle(chip, columns)}
                  onClick={() => startEditingChip(index, chip)}
                  onDelete={undefined}
                />
              ))}
              {tempValue.length > 0 && (
                <Chip
                  variant="outlined"
                  label={
                    <>
                      {tempValue}
                      <IconButton
                        onClick={handleConfirmValue}
                        size="small"
                        sx={{ ml: 1, p: 0 }}
                      >
                        <CheckCircleOutlineIcon fontSize="small" />
                      </IconButton>
                    </>
                  }
                />
              )}
              <InputBase
                inputRef={inputRef}
                onChange={handleChange}
                onPaste={handlePaste}
                placeholder={selectedValues.length === 0 && tempValue === "" ? "Introduzca una expresión aquí..." : ""}
                sx={{ fontSize: 16, flexGrow: 1, minWidth: "150px"}}
                multiline
                maxRows={3}
                endAdornment={
                  <InputAdornment position="end">
                    <CloseIcon fontSize='small'
                    style={{
                    ...closeIconStyle,
                    color: '#FF5549',
                    borderColor: "#FF5549",
                  }}
                    onClick={handleClear} />
                  </InputAdornment>
                }
                onKeyDown={(e) => {
                  handleKeyDown(e);
                  handleOpenMenu();
                }}
                onClick={handleOpenMenu}
              />
            </Box>
            <Menu
              anchorEl={boxRef.current}
              open={menuOpen}
              onClose={closeMenu}
              anchorReference="anchorPosition"
              anchorPosition={anchorPosition}
              disableAutoFocusItem
              disableEnforceFocus
              disableRestoreFocus
              PaperProps={{
                style: {
                  width: anchorPosition ? anchorPosition.width : "auto",
                  maxHeight: 200,
                  marginTop: '5px'
                },
              }}
            >
              {filteredOptions.map((option, index) => (
                <MenuItem key={index} onClick={() => handleAddChip(option)}>
                  {option}
                </MenuItem>
              ))}
            </Menu>
          </React.Fragment>
        ))}
        {validating ? ( <MuiRefreshIcon disabled={validating} />) :
          (<MuiCheckCircleIcon
            ref={el => { if (el) btnValidateRef.current = el }}
            onClick={selectedValues.length === 0 ? null : verificationExpression}
            fontSize='small'
            disabled={selectedValues.length === 0}
          />)}
        <Grid>
          <ToastValidation
            open={open}
            anchorEl={btnValidateRef.current}
            headerText={t('formule_valid')}
            textMessage={message}
            handleClose={handleClose}
            createAlias={createAlias}
            setSelectedValues={setSelectedValues}
            isDisabledValidateStructure={true}
          />
        </Grid>
      </MuiGridExpression>
      <Grid>
        {type === 'list' && (
          <List
            columns={columnsListOrder || []}
            alias={alias}
            formula={formulaWithAlias}
            setNewOrder={setColumnListOrder}
            realColumns={columnsList}
            calculator={calculator}
            action={action}
            setIdForm={setIdInstanceForm}
            setSelectedValues={setSelectedValues}
            columnSelect={columns}
          />
        )}
        {type === 'statistics' && (
          <Stat
            columns={columnsOrderStatistics || []}
            setNewOrder={setColumnListOrderStats}
            alias={alias}
            calculator={calculator}
            formula={formulaWithAlias}
            realColumns={columnsList}
            action={action}
            setIdForm={setIdInstanceForm}
            setSelectedValues={setSelectedValues}
            columnSelect={columns}
          />
        )}
        <MuiGridCalculator item xs={12} type={type}>
          <ButtonPanel formular={formular} deleteLastElement={deleteLastElement} />
        </MuiGridCalculator>
      </Grid>
    </Grid>
  </>

)
}

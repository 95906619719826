import { styled, Typography, Grid } from '@mui/material';
import { lighten } from '@mui/material/styles';
import { GLOBAL } from '../../../../../../../../../utils/constants';

export const MuiGridTitle = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start'
}));

export const MuiGridCalculator = styled(Grid)(({ theme, type }) => ({
  borderTop:'2px solid #E0E0E0',
  marginRight:'10px',
  marginTop: type === 'histogram' || type === 'recommendations' ? '24px' : '-4.5rem',
  height:'180px',
  maxWidth:'none',
  display:'flex'
}));

export const MuiGridExpression = styled(Grid)(({ theme }) => ({
  maxHeight:'350px',
  display:'flex',
  alignItems: "center"
}));

export const MuiAddCaseText = styled(Typography)(({ theme }) => ({
  color: lighten(theme.palette.primary.main, 0.1),
  fontWeight: 500,
  fontFamily: 'Montserrat'
}));

export const getChipStyle = (option, columns) => {
  return {
    fontFamily: 'Montserrat',
    fontWeight: 400,
    fontSize: columns.includes(option) ? 14 : 16,
    color: columns.includes(option) ? '#254957' : '#143440',
    textTransform: columns.includes(option.toLowerCase()) ? 'lowercase' : 'inherit' ,
    background: columns.includes(option.toLowerCase()) ? lighten(localStorage.getItem('colorPrimary'), 0.9)  : '#EEEEEE',
    border: 'none'
  }
}

export const listElementsStyle = {
  fontFamily:'Montserrat',
  fontWeight:400,
  fontSize:16,
  color:'#000000'
}

export const closeIconStyle = {
  border: '1px solid',
  borderRadius: '50%',
  cursor: 'pointer',
  marginRight: 1
}
